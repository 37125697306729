<template>
  <div class="box wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">我要入园</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterprisesInPark' }">园区入驻</el-breadcrumb-item>
      <el-breadcrumb-item>项目清单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="title">
        <div class="line"></div>
        <div class="text">项目清单</div>

          <el-button class="btn" type="primary" @click="applyTypeChooseDialogVisable = true">新增申请项目</el-button>
          <!-- <el-button class="btn" type="primary" @click="toPage">在线申请</el-button> -->
        
      </div>
      <h2>一般性项目</h2>
      <div class="table-box">
        <el-table v-loading="queryTable.loading" :data="queryTable.table" style="width: 100%" tooltip-effect="light">
          <el-table-column type="index" label="序号" width="50"> </el-table-column>
          <el-table-column prop="nameOfEnterprise" label="企业名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="entryName" label="项目名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="totalProjectInvestment" label="项目总投资"></el-table-column>
          <el-table-column prop="projectStatusName" label="项目状态"></el-table-column>
          <el-table-column prop="applyTime" label="申请时间"></el-table-column>
          <!-- <el-table-column prop="projectStatusName" label="提交转态"></el-table-column> -->
          <!-- <el-table-column prop="checkTime" label="审核时间"></el-table-column> -->
          <el-table-column label="审核状态">
            <template slot-scope="scope">
              <el-button @click="show(scope.row,'General')" style="color: #135694" type="text">{{ formatterStatus(scope.row) }}</el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="companyName" label="所在企业" show-overflow-tooltip></el-table-column> -->
          <el-table-column label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.projectStatus === '0'" @click="handleSubmitCheck(scope.row,'general')" style="color: #135694" type="text">提交审核</el-button>
              <el-button @click="view(scope.row.projectId, 'General')" style="color: #135694" type="text">详情</el-button>
              <el-button @click="view(scope.row.projectId, 'Progress','general')" style="color: #135694" type="text">查看进度</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-row style="margin-top: 25px">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :page-size="queryTable.pageSize"
            :total="queryTable.count"
            :current-page.sync="queryTable.page"
            @current-change="queryTable.search()"
          ></el-pagination>
        </el-row> -->
      </div>
      <h2 style="margin-top:30px;">安全应急类项目</h2>
      <div class="table-box">
        <el-table v-loading="querySecurityTable.loading" :data="querySecurityTable.table" style="width: 100%" tooltip-effect="light">
          <el-table-column type="index" label="序号" width="50"> </el-table-column>
          <el-table-column prop="nameOfEnterprise" label="企业名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="entryName" label="项目名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="totalProjectInvestment" label="项目总投资"></el-table-column>
          <el-table-column prop="registeredCapital" label="注册资本"></el-table-column>
          <el-table-column prop="projectStatusName" label="项目状态"></el-table-column>
          <el-table-column prop="projectContact" label="项目联系人"></el-table-column>
          <el-table-column prop="contactWay" label="联系方式"></el-table-column>
          <!-- <el-table-column prop="projectStatusName" label="提交转态"></el-table-column> -->
          <el-table-column label="审核状态">
            <template slot-scope="scope">
              <el-button @click="show(scope.row,'safe')" style="color: #135694" type="text">{{ formatterStatus(scope.row) }}</el-button>
            </template> 
          </el-table-column>
          <!-- <el-table-column prop="companyName" label="所在企业" show-overflow-tooltip></el-table-column> -->
          <el-table-column label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.projectStatus === '0'" @click="handleSubmitCheck(scope.row,'safe')" style="color: #135694" type="text">提交审核</el-button>
              <el-button @click="view(scope.row.projectId, 'Security')" style="color: #135694" type="text">详情</el-button>
              <el-button @click="view(scope.row.projectId, 'Progress','safe')" style="color: #135694" type="text">查看进度</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-row style="margin-top: 25px">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :page-size="queryTable.pageSize"
            :total="queryTable.count"
            :current-page.sync="queryTable.page"
            @current-change="queryTable.search()"
          ></el-pagination>
        </el-row> -->
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" title="审核状态" width="65%" :visible.sync="dialogVisable" :append-to-body="true">
      <div class="table-box">
        <el-table v-loading="loading" :data="table" style="width: 100%" tooltip-effect="light">
          <el-table-column type="index" label="序号" width="50"> </el-table-column>
          <el-table-column prop="time" label="审核日期"></el-table-column>
          <el-table-column prop="showName" label="审核单位"></el-table-column>
          <el-table-column prop="checkStatus" label="审核状态"></el-table-column>
          <el-table-column prop="remark1" label="审核意见"></el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="新增申请项目" :visible.sync="applyTypeChooseDialogVisable" width="100">
      <div style="display: flex;align-items: center;flex-direction: column; justify-content: space-between;">
          <div><el-button type="primary" @click="handleGotoApplyPage('General')">一般项目入园申请</el-button></div>
          <div style="margin-top:10px;"> <el-button  type="primary" @click="handleGotoApplyPage('Security')">应急项目入园申请</el-button></div>
      </div>
       
    </el-dialog>
  </div>
</template>

<script>
import { QueryTable } from '@/assets/js/QueryTable';
export default {
  name: 'policyService',
  data() {
    return {
      loading: false,
      dialogVisable: false,
      applyTypeChooseDialogVisable:false, //申请类型弹窗
      status: [],
      queryTable: new QueryTable({}, {}, this.$api.findProjectList),
      querySecurityTable: new QueryTable({},{},this.$api.findSafeProjectList),
      table: [],
    };
  },
  methods: {
    async handleSubmitCheck(item ,type){
      const {projectId,totalProjectInvestment} = item;
      console.log(`提交审核`,{type,projectId,totalProjectInvestment});
      if(!totalProjectInvestment){
        this.$message.warning("请先填写拟投资项目情况信息")
        return;
      }
      try{
           const loadinger = this.$loading(`请稍后`)
           if(type === 'safe'){
            await this.$api.submitDraftSafeProjectById({projectId})
          }else{
            await this.$api.submitDraftProjectById({projectId})
          }
          loadinger.close()
          this.loadData();
          this.$message.success(`提交成功`);
      }catch(e){
        loadinger.close()
        console.error(`提交失败`,e)
        this.$message.error(`提交失败`);

      }
    },
    handleGotoApplyPage(path){
       this.$router.push({ name: path });
    },
    async show(row,mode) {
      this.dialogVisable = true;
      this.loading = true;

      let res = await ( mode==="safe" ? 
      this.$api.getSafeProjectCheckDetailListByProjectId({ projectId: row.projectId })  : 
      this.$api.getProjectCheckDetailListByProjectId({ projectId: row.projectId }))

      this.table = res.result;
      this.loading = false;


    },
    view(id, name,type='') {
      console.log(`查看进度`,{id,name})
      this.$router.push({ name, params: { id },query:{type,form:'basicInfo'} });
    },
    formatterStatus(row) {
      // let status = this.status.filter((v) => {
      //   return row.checkStatus == v.itemValue;
      // })[0];
      return row.projectSmallStepName // status ? status.itemDesc : '';
    },
    loadData(){
        this.queryTable.search();
        this.querySecurityTable.search();
    }
  },

  created() {
    this.loadData();
    this.$api.getkey({ dictKey: 'check_status' }).then((res) => {
      this.status = res.result;
    });
  },
};
</script>

<style lang='scss' scoped>
 .el-table__empty-block{
      border-left: 1px #ddd solid;
      border-right: 1px #ddd solid;
      box-sizing: border-box;
      .el-table__empty-text{
        color: #858585;
        height: auto;
        // background: url("../assets/img/no-data@1x.png") center 50px no-repeat;
      }
    }
.box {
  .content {
    padding: 20px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 20px;
      .line {
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text {
        font-size: 26px;
        color: #333333;
        line-height: 36px;
        font-weight: 600;
        margin-right: 5px;
      }
    }
    .table-box {
      margin-top: 20px;
    }
  }
}
</style>
